<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">
          Consumer update
        </h4>
      </CCol>

      <CCol sm="8"></CCol>
    </CRow>
    <CCard class="p-3">
      <CRow>
        <CCol>
          <div v-if="serverErrors.length">
            <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
              <span v-for="err in serverErrors" :key="err">{{ err }}</span>
            </CAlert>
          </div>
          <form @submit.prevent="searchConsumer">
            <div class="row">
              <div class="col-md-9 text-center actions">
                <input
                    type="search"
                    class="form-control"
                    v-model="search"
                    placeholder="Search by A/C Number or Meter No or Mobile No"
                    required
                />
              </div>
              <div class="col-md-3">
                <button type="submit" class="btn btn-primary">
                  Search
                </button>
              </div>
            </div>
          </form>
          <CRow class="m-1 mt-3 border" v-if="meterConsumer && meterConsumer.account_number">
            <CCol sm="5">
              <table>
                <tr>
                  <td class="py-1">A/c No :</td>
                  <td class="py-1">{{ meterConsumer.account_number }}</td>
                </tr>
                <tr>
                  <td class="py-1">Name :</td>
                  <td class="py-1">{{ meterConsumer.name }}</td>
                </tr>
                <tr>
                  <td class="py-1">Address :</td>
                  <td class="py-1">{{ meterConsumer.address }}</td>
                </tr>
                <tr>
                  <td class="py-1"></td>
                  <td class="py-1">{{ meterConsumer.address2 }}</td>
                </tr>
                <tr>
                  <td class="py-1">Contact :</td>
                  <td class="py-1">{{ meterConsumer.mobile_number }}</td>
                </tr>
              </table>
            </CCol>
            <CCol sm="7">
              <CRow>
                <CCol sm="6">
                  <table>
                    <tr>
                      <td class="py-1">Class :</td>
                      <td class="py-1">{{ meterConsumer.class }}</td>
                    </tr>
                    <tr :class="[{'border-danger h5 text-danger': history.column === 'category'}]">
                      <td class="py-1">Category :</td>
                      <td class="py-1">{{ meterConsumer.category }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Meter No :</td>
                      <td class="py-1">{{ meterConsumer.meter.meter_number }}</td>
                    </tr>
                    <tr :class="[{'border-danger h5 text-danger': history.column === 'meter_status'}]">
                      <td class="py-1">Status :</td>
                      <td class="py-1">{{ meterConsumer.meter.meter_status }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Ward No :</td>
                      <td class="py-1">{{ meterConsumer.ward_number }}</td>
                    </tr>
                  </table>
                </CCol>
                <CCol sm="6">
                  <table>
                    <tr :class="[{'border-danger h5 text-danger': history.column === 'meter_type'}]">
                      <td class="py-1">Meter Type :</td>
                      <td class="py-1">{{ meterConsumer.meter.meter_type }}</td>
                    </tr>
                    <tr :class="[{'border-danger h5 text-danger': history.column === 'connection_size'}]">
                      <td class="py-1">Size :</td>
                      <td class="py-1">{{ meterConsumer.meter.connection_size }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Domestic :</td>
                      <td class="py-1">{{ meterConsumer.domestic }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Non-Domestic :</td>
                      <td class="py-1">{{ meterConsumer.non_domestic }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">MI :</td>
                      <td class="py-1">{{ meterConsumer.mi_code }}</td>
                    </tr>
                  </table>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <validation-observer ref="formValidator">
            <form class="border m-1 mt-3 p-2"
                  v-on:submit.prevent="addHistory"
                  v-if="meterConsumer && meterConsumer.account_number"
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Column</label>
                    <select
                        @change="history.new_value=''"
                        name="type"
                        v-model="history.column"
                        class="form-control"
                        required
                    >
                      <option value="">Select Change Column</option>
                      <option value="category">Category</option>
                      <option value="meter_status">Meter Status</option>
                      <option value="meter_type">Meter Type</option>
                      <option value="connection_size">Size of Conn</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>New Value</label>
                    <select
                        @change="getChangeNewValue"
                        name="category"
                        v-model="history.new_value"
                        class="form-control"
                        required
                    >
                      <option value="">Please Select</option>
                      <option v-for="(item, itemKey) in constants[history.column]" v-if="isShow(item)" :value="itemKey">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-if="history.column == 'category' && history.new_value ==1 " class="col-md-6">
                  <div class="form-group">
                    <label>Non Domestic %</label>
                    <input
                        class="form-control"
                        type="text"
                        v-model="history.non_domestic"
                        placeholder="Non Domestic %"
                    />
                  </div>
                </div>
                <div v-if="history.column == 'meter_status' && history.new_value ==2 " class="col-md-6">
                  <div class="form-group">
                    <label>Avg Consumption</label>
                    <input
                        class="form-control"
                        type="text"
                        v-model="history.avg_consumption"
                        placeholder="Avg Consumption"
                    />
                  </div>
                </div>

                <div class="col-md-6 col-3">
                  <div class="form-group">
                    <label>Action Date</label>
                    <el-date-picker
                        required
                        class="w-100 p-0"
                        v-model="history.action_date"
                        type="date"
                        placeholder="Enter Action Date"
                        :format="$datePickerFormat"
                        value-format="yyyy-MM-dd"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label>Remarks</label>
                    <textarea class="form-control" v-model="history.remarks"></textarea>
                  </div>
                </div>
              </div>

              <button v-if="meterConsumer && meterConsumer.account_number" type="submit" class="btn btn-primary">
                Update
              </button>
            </form>
          </validation-observer>
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>


<script>
import moment from "moment/moment";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "CreateTicket",
  data: () => {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "All consumer history",
        path: "/history",
        isActive: false
      }, {text: "Consumer Update", path: "", isActive: true}],
      search: "",
      meterConsumer: {},
      history: {
        customer_id: "",
        customer_account_id: "",
        meter_id: "",
        meter_number: "",
        column: "category",
        action_date: moment().format('YYYY-MM-DD'),
        old_value: "",
        new_value: "",
        avg_consumption: "",
        non_domestic: "",
        remarks: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
    };
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  computed: {
    ...mapGetters("Constant", ["constants"])
  },
  methods: {
    isShow(item) {
      const {column} = this.history;
      if (column !== 'category') {
        return this.meterConsumer.meter[column] !== item
      }
      return this.meterConsumer[column] !== item
    },
    getChangeNewValue() {
      const {column, new_value} = this.history;

      if (column === 'category') {
        this.history.non_domestic = new_value == 1 ? this.meterConsumer.non_domestic : '';
      }

      if (column === 'meter_status') {
        if (new_value != 2) {
          return this.history.avg_consumption = '';
        }
        this.$store.dispatch("Settings/loading", true);
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/last90-days-avg-consumption/${this.meterConsumer.meter.id}`)
            .then(({data}) => {
              console.log(data, 'data')
              this.history.avg_consumption = data.averageDifference || this.meterConsumer.avg_consumption;
              this.$store.dispatch("Settings/loading", false);
            })
            .catch(error => {
              this.$toastr.e("Consumer not found.", "Failed!");
              this.$store.dispatch("Settings/loading", false);
            });
      }
    },
    searchConsumer() {
      this.$store.dispatch("Settings/loading", true);
      return this.axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/admin/consumers/search?search=${this.search}`).then(({data}) => {
                this.$store.dispatch("Settings/loading", false);
                this.meterConsumer = data.data;
                this.history.customer_id = data.data.customer_id
                this.history.customer_account_id = data.data.id
                this.history.meter_id = data.data.meter.id
                this.history.meter_number = data.data.meter.meter_number
                this.history.non_domestic = data.data.non_domestic
              }, (error) => {
                this.$toastr.e("Consumer Info not found.", "Failed!");
                this.$store.dispatch("Settings/loading", false);
                this.resetHistoryData();
              }
          );
    },
    resetHistoryData() {
      this.history = {
        customer_id: "",
        customer_account_id: "",
        meter_id: "",
        meter_number: "",
        column: "category",
        action_date: moment().format('YYYY-MM-DD'),
        old_value: "",
        new_value: "",
        remarks: "",
      };
      this.meterConsumer = {};
    },

    addHistory() {
      this.$store.dispatch("Settings/loading", true);
      axios.post(`/admin/history/create`, this.history).then(({data}) => {
        this.search = ''
        this.serverErrors = [];
        this.$store.dispatch("Settings/loading", false);
        this.resetHistoryData();
        this.$toastr.s(
            "Consumer history Successfuly updated!",
            "Consumer Updated"
        ), (error) => {
          this.serverErrors = [];
          if (error.response.data) {
            this.serverErrors.push(error.response.data.message);
          }
          this.$toastr.e(error.response.data.message, "Failed!");
          this.$store.dispatch("Settings/loading", false);
        }
      })
    },
  },
  mounted() {
    this.$store.dispatch("Constant/getConstants", 'category,connection_size,meter_type,meter_status');
  },
}
</script>
